.icon-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: 0;
  -webkit-transition: color 0.1s linear;
  transition: color 0.1s linear;
}

.icon-btn.icon-btn--light {
  color: #feffff;
}

.icon-btn.icon-btn--dark {
  color: #17252a;
}

.icon-btn.icon-btn--light:focus,
.icon-btn.icon-btn--light:hover,
.icon-btn.icon-btn--dark:focus,
.icon-btn.icon-btn--dark:hover {
  color: #3aafa9;
}

.header {
  background-color: #17252a;
  box-shadow: 0 0.3rem 1rem #2b7a78;
  color: #feffff;
  width: 100%;
}

.header .header__content {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin: 0 auto;
  max-width: 75rem;
  padding: 0 1rem;
}

.header a {
  color: #feffff;
  outline: none;
  text-decoration: none;
  -webkit-transition: color 0.1s linear;
  transition: color 0.1s linear;
}

.header a:focus,
.header a:hover {
  color: #3aafa9;
}

.header .icon-btn {
  margin-left: auto;
}

.recipe-card {
  float: left;
  padding: 0.5rem;
  width: 100%;
}

@media (min-width: 48em) {
  .recipe-card {
    width: 33.33333%;
  }
}

.recipe-card .recipe-card__content {
  background-color: #def2f1;
  border-radius: 0.25rem;
  border: 0.625rem solid #def2f1;
  box-shadow: 0 0 1rem #2b7a78;
  cursor: pointer;
  position: relative;
  width: 100%;
}

.recipe-card .recipe-card__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 10rem;
  width: 100%;
}

.recipe-card .recipe-card__title {
  background-color: #2b7a78;
  color: #feffff;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin: 0;
  padding: 1rem;
  text-align: center;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
  width: 100%;
  word-wrap: break-word;
}

.recipe-card:hover .recipe-card__title {
  background-color: #def2f1;
  color: #2b7a78;
}

.recipe-list {
  margin: 1.5rem auto;
  max-width: 75rem;
  width: 100%;
}

.recipe-list .recipe-list__row {
  padding: 0 0.5rem;
  width: 100%;
}

.recipe-list .recipe-list__row::after {
  clear: both;
  content: '';
  display: table;
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  color: #17252a;
  height: 100%;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
}

.modal .modal__container {
  height: 100%;
}

.modal .modal__content {
  background-color: #feffff;
  border-radius: 0;
  height: 100%;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  width: 100%;
}

.modal .modal__header {
  display: -webkit-flex;
  display: flex;
  padding: 1rem 1rem 0;
  width: 100%;
}

.modal .modal__title {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  white-space: pre-line;
  word-wrap: break-word;
}

.modal .icon-btn {
  -webkit-align-self: flex-start;
          align-self: flex-start;
  margin-top: 1rem;
  margin-left: auto;
}

.modal__body {
  padding: 0 1rem 1rem;
}

@media (min-width: 48em) {
  .modal .modal__container {
    height: auto;
    height: initial;
  }

  .modal .modal__content {
    border-radius: 0.25rem;
    height: auto;
    height: initial;
    margin: 2rem auto;
    max-width: 45.75rem;
    width: 45.75rem;
  }
}

.label {
  display: inline-block;
  font-weight: bold;
  margin-top: 1.17rem;
  width: 100%;
}

.input {
  background-color: #def2f1;
  border: none;
  border-bottom: 0.0625rem solid #def2f1;
  color: #17252a;
  display: block;
  margin: 0.5rem 0;
  outline: none;
  padding: 0.5rem;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}

/* Remove Firefox invalid red glow */

.input:invalid {
  box-shadow: none;
}

.input:-moz-submit-invalid {
  box-shadow: none;
}

.input:-moz-ui-invalid {
  box-shadow: none;
}

.input:active,
.input:focus {
  border-bottom: 0.0625rem solid #3aafa9;
  box-shadow: 0 0.0625rem 0 0 #3aafa9;
}

.textarea {
  background-color: #def2f1;
  border: none;
  border-bottom: 0.0625rem solid #def2f1;
  color: inherit;
  display: block;
  height: 10rem;
  margin: 0.5rem 0;
  outline: none;
  padding: 0.5rem;
  resize: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}

/* Remove Firefox invalid red glow */

.textarea:invalid {
  box-shadow: none;
}

.textarea:-moz-submit-invalid {
  box-shadow: none;
}

.textarea:-moz-ui-invalid {
  box-shadow: none;
}

.textarea:active,
.textarea:focus {
  border-bottom: 0.0625rem solid #3aafa9;
  box-shadow: 0 0.0625rem 0 0 #3aafa9;
}

.btn {
  border: none;
  border-radius: 0.25rem;
  color: #feffff;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  padding: 0.5rem 1rem;
  outline: none;
  -webkit-transition: background-color 0.1s linear;
  transition: background-color 0.1s linear;
}

.btn.btn--default {
  background-color: #3aafa9;
}

.btn.btn--default:focus,
.btn.btn--default:hover {
  background-color: #219690;
}

.btn.btn--danger {
  background-color: #d9534f;
}

.btn.btn--danger:focus,
.btn.btn--danger:hover {
  background-color: #c9302c;
}

.recipe-form {
  width: 100%;
}

.recipe-form .btn {
  margin-top: 1.17rem;
  margin-right: 1rem;
}

.recipe-display .recipe-display__row {
  padding: 0;
  width: 100%;
}

.recipe-display .recipe-display__row::after {
  clear: both;
  content: '';
  display: table;
}

.recipe-display .recipe-display__img {
  margin: 1.17rem 0;
  width: 100%;
}

.recipe-display .recipe-display__title {
  margin-top: 0;
}

.recipe-display .recipe-display__text {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin-bottom: 0;
  margin-right: 0;
  white-space: pre-line;
  word-wrap: break-word;
}

.recipe-display .btn {
  margin-top: 1.17rem;
  margin-right: 0.5rem;
}

.recipe-display .btn:last-child {
  margin-right: 0;
}

.recipe-display .recipe-display__ingredients,
.recipe-display .recipe-display__method {
  padding: 1.17rem 0;
}

.recipe-display .recipe-display__ingredients {
  width: 100%;
}

@media (min-width: 48em) {
  .recipe-display .recipe-display__img {
    float: right;
    width: 50%;
  }

  .recipe-display .recipe-display__ingredients {
    float: left;
    width: 50%;
  }

  .recipe-display .recipe-display__text {
    margin-right: 1rem;
  }

  .recipe-display .btn {
    margin-top: 0;
  }
}

.footer {
  background-color: #17252a;
  box-shadow: 0 -0.3rem 1rem #2b7a78;
  color: #feffff;
  width: 100%;
}

.footer .footer__content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin: 0 auto;
  max-width: 75rem;
  padding: 2rem 1rem;
}

.footer a {
  color: #feffff;
  outline: none;
  text-decoration: none;
  -webkit-transition: color 0.1s linear;
  transition: color 0.1s linear;
}

.footer a:focus,
.footer a:hover {
  color: #3aafa9;
}

html {
  background-color: #3aafa9;
  font-family: 'Raleway', sans-serif;
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow: auto;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

